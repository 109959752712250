import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/header/hero'
import Container from '../components/container'
import Newsletter from '../components/footer/newsletter'

const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 450;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

export default ({data}) => {
  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    centerMode: true,
    // centerPadding: 0,
    variableWidth: true,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          // variableWidth: false,
        }
      }
    ],
  };
  return (
    <Layout>
      <SEO title={data.wordpressPage.yoast_title} meta={data.wordpressPage.yoast_meta} path={data.wordpressPage.slug}/>
      <Hero mode="default"
        title={data.wordpressPage.acf.header_text}
        content={data.wordpressPage.acf.header_content}
        image={ data.wordpressPage.featured_media ?
          data.wordpressPage.featured_media.localFile.childImageSharp.fluid
        :
          null
        }
        button={
          {
            title: typeof data?.wordpressPage?.acf?.header_button?.title !== 'undefined' ? data.wordpressPage.acf.header_button.title : '',
            url: typeof data?.wordpressPage?.acf?.header_button?.url !== 'undefined' ? data.wordpressPage.acf.header_button.url : '',
            target: typeof data.wordpressPage.acf.header_button.target !== 'undefined' ? data.wordpressPage.acf.header_button.target : '',
          }
        }
      />
      <div className="scholarshipsApplication">
        <Container style={{maxWidth: '700px'}}>
          <div className="contentEditorBlock" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}/>
          <div className="applicationEmbed" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.application_embed }} />
        </Container>
      </div>
      <Newsletter />
    </Layout>
  )
}
export const pageQuery = graphql`
query donationApplicationQuery {
  wordpressPage(wordpress_id: {eq: 449}) {
    acf {
      header_content
      header_text
      header_button {
        url
        title
        target
      }
      application_embed
    }
    content
    title
    slug
    yoast_meta {
      name
      property
    }
    yoast_title
    featured_media {
      source_url
      localFile {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
}
`

